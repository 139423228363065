/* eslint-disable @typescript-eslint/ban-ts-comment */
import type { History } from "history";
import type { ReactNode } from "react";
import { StaticRouter } from "react-router-dom/server";

import { useServer } from "@shared/hooks/useServer/useServer";
import { ScrollRestoration, type BrowserRouterProps } from "@shared/react-router-dom";
import { isSSR } from "@shared/utils/fullscriptEnv/fullscriptEnv";

import { BrowserRouter } from "./BrowserRouter/BrowserRouter";

interface Props extends BrowserRouterProps {
  history?: History;
  children: ReactNode;
}

const Router = ({ children, ...rest }: Props) => {
  const { url } = useServer();

  if (isSSR()) {
    return <StaticRouter location={url}>{children}</StaticRouter>;
  }

  return (
    <BrowserRouter {...rest}>
      {children}
      <ScrollRestoration />
    </BrowserRouter>
  );
};

export { Router };
